import React from "react";
import { Link } from 'react-router-dom';

function Header() {
    return (
        <> 
        <div className="main">
            <div className="nav">
                <div className="nav-row">
                    <div>
                        <h1 className="title">Maiden, America</h1>
                    </div>
                    <div className="btn-group">
                        <button className="shadow login-button"><Link href="/login">Login</Link></button>
                        <button className="shadow login-button"><Link href="/register">Register</Link></button>
                    </div>
                </div>
            </div>
        </div>
        </>
     );
}

export default Header;