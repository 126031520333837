import React, { useState } from 'react';

function SignUp() {

    const [form, setForm] = useState({
        name: "",
        email: "",
    });

    const updateForm = (field, value) => {
        setForm({ ...form, [field]: value });
    }

    const handleSubmit = (e) => {
        window.alert('Your form has been submitted!')
        console.log("Form submitted:", form);
    }

    return (
        <div className="registration-body">
            <h1 className="text-4xl">Get notified when Maiden is live!</h1>
            <form onSubmit={handleSubmit}>
                <div className="flex flex-row mt-8">
                    <input
                        type="text"
                        placeholder="Your Name"
                        className="p-2 rounded-xl"
                        id="name"
                        value={form.name}
                        onChange={(e) => updateForm("name", e.target.value)}
                    />
                </div>
                <div className="flex flex-row mt-8">
                    <input
                        type="email"
                        placeholder="Your Email Address"
                        className="p-2 rounded-xl"
                        id="email"
                        value={form.email}
                        onChange={(e) => updateForm("email", e.target.value)}
                    />
                </div>
                <button type="submit" className="mt-8 shadow submit-button">Submit</button>
            </form>
        </div>
    );
}

export default SignUp;
